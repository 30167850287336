<script>
import templateAPi from '../../api/template'

export default {
  data() {
    return {
      ready: false,
      searching: false,
      searchingBottom: false,
      templates: [],
      loading: false,
      byTemplateModal: false,
      form: {},
      templateForm: false,
      selectedTemplateId: '',
      gettingTemplateData: '',
      searchKeyword: '',
      initiatedTransaction: {},
      initiatingTransaction: false,
      templateData: {},
      width: 320,
      meta: { limit: 20, offset: 0, total: '' },
      user: {
        amount: '',
        full_name: '',
        email: ''
      },
      paystackkey: process.env.VUE_APP_PAYSTACK_KEY
    }
  },
  computed: {
    // width() {
    //   return window.innerWidth > 0 ? window.innerWidth : screen.width
    // },
  },
  created() {
    this.getTemplate(this.meta)
  },
  mounted() {
    this.width = window.innerWidth > 0 ? window.innerWidth : screen.width
    window.addEventListener('scroll', () => {
      if (
        window.innerHeight + window.scrollY - 700 >=
          document.body.offsetHeight &&
        this.ready &&
        this.meta.offset < this.meta.total
      ) {
        this.ready = false
        this.meta.offset = this.meta.offset + this.meta.limit
        this.getMoreTemplates(this.meta)
      }
    })
  },
  methods: {
    async searchTemplate() {
      if (this.searchKeyword === '') {
        this.getTemplate(this.meta)
        return
      }
      const data = { noAuth: true }

      data.keyword = this.searchKeyword

      this.loading = true
      const req = await templateAPi(this.axios).getAll({
        params: data
      })
      this.templates = req.data.data
      this.loading = false
    },
    onCloseForm() {
      this.selectedTemplateId = ''
      this.templateForm = false
      this.templateData = {}
    },
    async getTemplateData(id) {
      this.selectedTemplateId = id
      const req = await templateAPi(this.axios).get({
        templateId: id,
        params: { noAuth: true }
      })
      this.templateData = req.data
      this.templateForm = true
      // console.log(this.templateData)
    },
    async handleForm() {
      this.initiatingTransaction = true
      const variables = []
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const element = this.form[key]
          variables.push({
            _id: key,
            value: element
          })
        }
      }

      const req = await templateAPi(this.axios).accessAsLawyer({
        templateId: this.selectedTemplateId,
        data: {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          category: 'template',
          categoryId: this.templateData._id,
          amount: this.templateData.cost,
          variables
        }
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.error.message
        })
        return
      }

      if (req.error) {
        this.selectedTemplateId = false
        this.initiatingTransaction = false
        this.$notification.error({
          message: 'Error',
          description: req.error.message
        })
        return
      }

      this.$notification.success({
        message: 'Success',
        description: req.message
      })

      this.initiatingTransaction = false
      this.selectedTemplateId = false
      this.$router.push('/lawyer/dashboard')
    },
    async getMoreTemplates(meta) {
      this.searchingBottom = true
      delete this.meta.total

      const req = await templateAPi(this.axios).getAll({
        params: { noAuth: true, ...meta }
      })

      this.templates = this.templates.concat(req.data.data)
      this.searchingBottom = false
      this.ready = true

      this.meta.total = req.data.meta.total
    },
    async getTemplate(meta) {
      this.loading = true
      delete this.meta.total
      const req = await templateAPi(this.axios).getAll({
        params: { noAuth: true, ...meta }
      })
      this.templates = req.data.data
      this.ready = true
      this.meta.total = req.data.meta.total
      this.loading = false
    },
    async accessAsLawyer() {}
  }
}
</script>

<template>
  <div>
    <a-drawer
      id="formTemp"
      title="Fill template form"
      :width="width > '640' ? 575 : 320"
      :visible="templateForm"
      @close="onCloseForm"
    >
      <h2 class="font-bold text-lg">
        {{ templateData.title }}
      </h2>
      <p class="text-sm text-gray-600">
        {{ templateData.description }}
      </p>
      <div class="my-3">
        <a-form-model layout="vertical">
          <a-form-model-item label="Email">
            <a-input v-model="user.email" placeholder="Enter your email" />
          </a-form-model-item>
          <a-form-model-item label="First Name">
            <a-input
              v-model="user.firstName"
              placeholder="Enter your first Name"
            />
          </a-form-model-item>
          <a-form-model-item label="Last Name">
            <a-input
              v-model="user.lastName"
              placeholder="Enter your last Name"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <hr class="my-3" />
      <div class="mt-3 pb-10">
        <a-form-model layout="vertical">
          <div v-for="(variable, i) in templateData.variables" :key="i">
            <template v-if="variable.fieldType === 'text'">
              <a-form-model-item
                :extra="variable.description"
                :label="variable.name || variable.title"
              >
                <a-input
                  v-model="form[variable._id]"
                  :placeholder="variable.name || variable.title"
                />
              </a-form-model-item>
            </template>
            <template v-if="variable.fieldType === 'select'">
              <a-form-model-item
                :extra="variable.description"
                :label="variable.name || variable.title"
              >
                <a-select
                  v-model="form[variable._id]"
                  :placeholder="variable.name || variable.title"
                >
                  <a-select-option
                    v-for="value in variable.values"
                    :key="value"
                    :value="value"
                  >
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </template>
            <template v-if="variable.fieldType === 'multichoice'">
              <a-form-model-item
                :extra="variable.description"
                :label="variable.name || variable.title"
              >
                <a-select
                  v-model="form[variable._id]"
                  mutiple
                  :placeholder="variable.name || variable.title"
                >
                  <a-select-option
                    v-for="value in variable.values"
                    :key="value"
                    :value="value"
                  >
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </template>
          </div>
        </a-form-model>
      </div>
      <div>
        <a-button :style="{ marginRight: '8px' }" @click="onCloseForm">
          Cancel
        </a-button>
        <a-button
          :loading="initiatingTransaction"
          type="primary"
          @click="handleForm"
        >
          Use Template
        </a-button>
      </div>
    </a-drawer>

    <div class="">
      <div class="flex">
        <a-input
          v-model="searchKeyword"
          type="text"
          placeholder="Search for template here"
          @keyup.enter="searchTemplate"
        />
        <div class="ml-4">
          <a-button @click="searchTemplate" type="primary">
            Search
          </a-button>
        </div>
      </div>
    </div>
    <div class="py-12 px-5">
      <div class="grid grid-cols-1 relative">
        <div class="w-full">
          <div v-if="loading" class="flex items-center justify-center">
            <a-spin />
          </div>
          <div v-else>
            <div class="grid md:grid-cols-4 grid-cols-1 gap-5">
              <div
                v-for="template in templates"
                :key="template._id"
                class="shadow-md rounded-md p-3 bg-white flex flex-col justify-between"
              >
                <div>
                  <img
                    :src="template.icon || '../../assets/images/template.jpeg'"
                    class="w-36 block mx-auto my-5"
                  />
                  <h2 class="text-lg font-bold mb-1">{{ template.title }}</h2>
                  <h3 class="font-bold text-xl text-gray-700 mb-3">
                    ₦{{ template.cost }}
                  </h3>

                  <p class="text-sm leading-normal text-gray-600 mb-3">
                    {{ template.description }}
                  </p>
                </div>
                <div>
                  <a-button
                    type="primary"
                    :loading="selectedTemplateId === template._id"
                    block
                    @click="getTemplateData(template._id)"
                  >
                    Use Template
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="searchingBottom" class="flex items-center justify-center mt-8">
        <a-spin />
      </div>
    </div>
  </div>
</template>

<style>
#formTemp label {
  @apply capitalize;
}
</style>
